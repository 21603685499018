import { pgTable, serial, text, timestamp, uniqueIndex } from 'drizzle-orm/pg-core';
import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import { z } from 'zod';

export const LeadsTable = pgTable(
	'leads',
	{
		id: serial('id').primaryKey(),
		firstName: text('firstName').notNull(),
		lastName: text('lastName').notNull(),
		email: text('email').notNull(),
		createdAt: timestamp('createdAt').defaultNow().notNull(),
	},
	(leads) => {
		return {
			uniqueIdx: uniqueIndex('unique_idx').on(leads.email),
		};
	}
);

export const insertLeadSchema = createInsertSchema(LeadsTable, {
	id: z.number(),
	createdAt: z.date(),
	firstName: z.string().min(1, 'Voornaam moet minimaal 1 karakter lang zijn'),
	lastName: z.string().min(1, 'Achternaam moet minimaal 1 karakter lang zijn'),
	email: z.string().email('Ongeldig e-mailadres'),
});
export const selectLeadSchema = createSelectSchema(LeadsTable);
export const requestSchema = insertLeadSchema.pick({ firstName: true, lastName: true, email: true });

export type Lead = z.infer<typeof insertLeadSchema>;
export type NewLead = z.infer<typeof requestSchema>;
